.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group,
.react-flow__node-custom {
  padding: 10px;
  border-radius: 7px;
  width: 160px;
  font-size: 12px;
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #1a192b;
  background-color: white;

  &.selectable {
    &:hover {
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
    }

    &.selected,
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 0.5px #1a192b;
    }
  }
}

.react-flow__node-custom {
    padding: 10px;
    border-radius: 7px;
    width: 160px;
    font-size: 13px;    
    color: rgb(5, 22, 77);
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(5, 22, 77);
    background-color: white;
  
    &.selectable {
      &:hover {
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
      }
  
      &.selected,
      &:focus,
      &:focus-visible {
        box-shadow: 0 0 0 0.5px #1a192b;
      }
    }
  }