:root {
    --primary: rgb(5, 22, 77);
    --bs-body-color: var(--primary);
    --bs-navbar-brand-color: #ffffff;
    --bs-navbar-toggler-icon-bg: #ffffff;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary);
    --bs-btn-disabled-border-color:var(--primary);
}

html {
    font-size: 14px;
}

h1 {
    font-size: 1.5rem;
}
h2 {
    font-size: 1.25rem;
}
h3 {
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    font-size: 1.1rem;
}

ol, ul {
    padding-left: 1rem;
    list-style-type: square;
}

b {
    background-color: transparent !important;
}

.list-heading {
    height: 40px;
    min-width: 150px;
    padding-left: 5px;
    vertical-align: middle;
    display: table-cell;
    font-weight: bold;
    border-bottom: 1px solid var(--primary);
}

.filter-heading {
    height: 40px;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    font-weight: bold;
    border-bottom: 1px solid var(--primary);
}

.filter-entry:hover {
    cursor: pointer;
}

.filter-active {
    font-weight: bold;
}

.list-row:hover {
    background-color: #f6f5f5 !important;

}

.navbar-brand {
    color: #ffffff;
    text-transform: uppercase;
    display: table-cell;
    vertical-align: bottom;
}

.navbar-brand:hover {
    color: #ffffff;
}

.nav-link {
    color: #ffffff;
    border-bottom: 1px solid transparent;
}

.nav-link:focus, .nav-link:hover {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}

button.nav-link {
    color: var(--primary);
}

button.nav-link:active, button.nav-link:focus, button.nav-link:hover {
    color: var(--primary);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}

a {
    color: rgb(5, 22, 77);
}

.bg-primary {
    background-color: rgb(5, 22, 77) !important;
}

nav.bg-dark {
    background-color: #505050 !important;
}
